import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

const ModalSave = (props) => {

  function handleClose(type) {
    props.onHide(type);
  }

  return (
    <>
      <Modal show={props.show} onHide={() => handleClose("close")}>
        <Modal.Header>
          <Modal.Title />
          <FontAwesomeIcon
            onClick={() => handleClose("close")}
            icon={faXmark}
            className="iconClose"
          />
        </Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon size="3x" icon={faCircleInfo} />
          ¿Estas seguro que quieres cerrar sesion?
        </Modal.Body>
        <Modal.Footer>
          <Button className="btnCancel" onClick={() => handleClose("close")}>
            Cancelar
          </Button>
          <Button className="btnSave" onClick={() => handleClose("save")}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSave;
