import React, { useRef, useState } from "react";
import "./responsivemenu.css";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ModalLogout from "../modals/ModalLogout";

const clickOutsideRef = (menu_ref, menu_toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    if (menu_toggle_ref.current && menu_toggle_ref.current.contains(e.target)) {
      menu_ref.current.classList.toggle("active");
    } else if (menu_ref.current && !menu_ref.current.contains(e.target)) {
      menu_ref.current.classList.remove("active");
    }
  });
};

const ThemeMenu = () => {
  const [subMenu, setSubMenu] = useState([]);
  const [subMenuActive, setSubMenuActive] = useState(false);

  const menu_ref = useRef(null);
  const menu_toggle_ref = useRef(null);
  const [show, setShow] = useState(false);
  
  const location = useLocation();
  const activeItem = sidebar_items.findIndex(
    (item) => item.firstMenu === location.pathname.split("/")[1]
  );

  clickOutsideRef(menu_ref, menu_toggle_ref);

  const setActiveMenu = () => menu_ref.current.classList.add("active");
  const closeMenu = () => menu_ref.current.classList.remove("active");

  const closeSubMenu = () => {
    setSubMenuActive(false);
    setSubMenu([]);
  };

  const Logout = (e) => {
    setShow(false);
    if (e === "save") {
      localStorage.removeItem("jwt");
      window.location.reload(false);
    }
  };

  const SidebarItem = (props) => {
    const active = props.active ? "active" : "";

    const clickMenu = (e) => {
      const found = sidebar_items.find((obj) => {
        return obj.id === e;
      });
      setSubMenu(found.sublist);
      setSubMenuActive(true);
    };

    return (
      <Link to={props.route} key={`${props.id}`} onClick={() => {
        closeMenu();
      }}>
        <div className="" key={props.id}>

        <div
          onClick={() => clickMenu(props.id)}
          className={`sidebar__item-inner ${active}`}
        >
          {props.icon && <i className={props.icon} />}
          <span>{props.title}</span>
        </div>
      </div>
      </Link>
      
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      <ModalLogout show={show} onHide={Logout} />
      <button
        ref={menu_toggle_ref}
        className="dropdown__toggle"
        onClick={() => setActiveMenu()}
      >
        <i className="bx bx-menu" />
      </button>
      <button className="dropdown__toggle" onClick={() => setShow(true)}>
        <i className="bx bx-log-out-circle bx-rotate-180" />
      </button>
      <div ref={menu_ref} className="theme-menu menuMobile">
        <button
          className="theme-menu__close"
          onClick={() => {
            subMenuActive ? closeSubMenu() : closeMenu();
          }}
        >
          <i className="bx bx-x" />
        </button>
        {subMenu.length > 0
          ? subMenu.map((item, index) => (
              <div className="sidebar__item-inner" key={`${item.id}`}>
                <Link
                  onClick={() => {
                    closeMenu();
                    closeSubMenu();
                  }}
                  to={item.route}
                  key={`${item.id}`}
                >
                  <div className="sidebar__subitem" key={`${item.id}`}>
                    <div className={`sidebar__subitem-inner`}>
                      <span>{item.name}</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          : sidebar_items.map((item, index) => (
              <SidebarItem
                title={item.display_name}
                icon={item.icon}
                active={index === activeItem}
                sublist={item.sublist ? item.sublist : []}
                route={item.route}
                id={item.id}
                key={item.id}
              />
            ))}
      </div>
    </div>
  );
};

export default ThemeMenu;
