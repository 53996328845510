import React, { useState } from "react";
import "./topnav.css";
import ModalLogout from "../modals/ModalLogout";

const TopNav = () => {

  const [show, setShow] = useState(false);

  const Logout = (e) => {
    setShow(false);
    if (e === "save") {
      localStorage.removeItem("jwt");
      window.location.reload(false);
    }
  };

  return (
    <div className="topnav">
      <ModalLogout show={show} onHide={Logout} />
      <div className="topnav__right">
        <div className="topnav__right-item">
          <div className="dropdown">
            <button
              className="dropdown__toggle"
              onClick={() => setShow(true)}
            >
              <div className="topnav__right-user">
                  <i className={"bx bx-log-out-circle bx-rotate-180"} />
                  <span>Logout</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
