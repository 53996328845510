const UserReducer = (state = '', action) => {
    switch(action.type){
        case 'SET_USER':
            return{
                ...state,
                user: action.payload
            }
        case "GET_TIPOS":
            return {
                ...state,
                tipos: action.payload,
            };
        default:
            return state
    }
}

export default UserReducer;