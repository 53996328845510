import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./layout.css";
import AllRoutes from "../Routes";
import RoutesAdmin from "../RoutesAdmin";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("../../pages/Home"));
const Productos = lazy(() => import("../../pages/admin/productos/Productos"));
const ProductosNuevo = lazy(() =>
  import("../../pages/admin/productos/ProductosNuevo")
);
const ProductosEdit = lazy(() =>
  import("../../pages/admin/productos/ProductosEdit")
);
const Categorias = lazy(() =>
  import("../../pages/admin/categorias/Categorias")
);
const CategoriasNuevo = lazy(() =>
  import("../../pages/admin/categorias/CategoriasNuevo")
);
const CategoriasEdit = lazy(() =>
  import("../../pages/admin/categorias/CategoriasEdit")
);
const Contenido = lazy(() => import("../../pages/admin/contenido/Contenido"));
const ContenidoNuevo = lazy(() =>
  import("../../pages/admin/contenido/ContenidoNuevo")
);
const ContenidoEdit = lazy(() =>
  import("../../pages/admin/contenido/ContenidoEdit")
);
const Producto = lazy(() => import("../../pages/Producto"));
const ListaCategorias = lazy(() => import("../../pages/Categorias"));
const ListaCategoriaId = lazy(() => import("../../pages/Categoria"));

const Layout = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AllRoutes />}>
          <Route index element={<Home />} />
          <Route path="producto/:id/" element={<Producto />} />
          <Route path="categorias/" element={<ListaCategorias />} />
          <Route path="categoria/:id/" element={<ListaCategoriaId />} />
          <Route path="admin/" element={<RoutesAdmin />}>
            <Route path="productos" index element={<Productos />} />
            <Route path="productos/nuevo" index element={<ProductosNuevo />} />
            <Route path="producto/:id" index element={<ProductosEdit />} />

            <Route path="categorias" index element={<Categorias />} />
            <Route
              path="categorias/nuevo"
              index
              element={<CategoriasNuevo />}
            />
            <Route path="categoria/:id" index element={<CategoriasEdit />} />

            <Route path="contenido" index element={<Contenido />} />
            <Route path="contenido/nuevo" index element={<ContenidoNuevo />} />
            <Route path="contenido/:id" index element={<ContenidoEdit />} />
          </Route>
          {/* <Route path="*" element={<NotFound />} /> */}
        </Route>
      </Routes>
    </Router>
  );
};

export default Layout;
