import React from "react";
import "./sidebar.css";
import logo from "../../assets/images/logo.png";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ResponsiveMenu from "../responsivemenu/ResponsiveMenu";
import TopNav from "../topnav/TopNav";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";

  return (
    <div className="sidebar__item" key={props.id}>
      <Link to={props.route} key={`${props.id}`}>
        <div className={`sidebar__item-inner ${active}`}>
          {props.icon && <i className={props.icon} />}
          <span>{props.title}</span>
        </div>
      </Link>

      {props.sublist.length > 0 && (
        <div className="sublist">
          {props.sublist.map((item, index) => (
            <Link to={item.route} key={`${item.id}`}>
              <SidebarSubItem
                title={item.name}
                sublist={item.sublist ? item.sublist : []}
                id={item.id}
              />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const SidebarSubItem = (props) => {
  return (
    <div className="sidebar__subitem" key={`${props.id}`}>
      <div className={`sidebar__subitem-inner`}>
        {props.icon && <i className={props.icon} />}
        <span>{props.title}</span>
      </div>
      {props.sublist.length > 0 && (
        <div className="sublist">
          {props.sublist.map((item, index) => (
            <SidebarItem
              title={item.name}
              sublist={item.sublist ? item.sublist : []}
              route={item.route}
              id={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar = (props) => {
  const location = useLocation();
  const activeItem = sidebar_items.findIndex(
    (item) => item.firstMenu === location.pathname.split("/")[1]
  );

  return (
    <div className="sidebar">
      <div className="itemsMenu">
        <Link to={"/"}>
          <div className="sidebar__logo">
            <img src={logo} alt="company logo" />
          </div>
        </Link>
        {sidebar_items.map(
          (item, index) =>
            !item.mobile && (
              <SidebarItem
                title={item.display_name}
                icon={item.icon}
                active={index === activeItem}
                sublist={item.sublist ? item.sublist : []}
                route={item.route}
                id={item.id}
                key={item.id}
              />
            )
        )}
      </div>
      <div className="menuUser">
        <TopNav />
      </div>
      <div className="iconMenu">
        <ResponsiveMenu />
      </div>
    </div>
  );
};

export default Sidebar;
