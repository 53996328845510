import React, { useRef, useState } from "react";
import { URL } from "../AppConfig";
import LoaderButton from "../components/loaderButton"
import { toast, ToastContainer } from "react-toastify";

const Login = (props) => {

  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)

  const refUser = useRef(null);
  const refPass = useRef(null);

  const enviarData = async (url, data) => {
    const respuesta = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await respuesta.json();
    return json;
  };

  const handleKeyPress = (e)  => {
    if(e.key === 'Enter'){
      handleLogin()
    }
  }

  const handleLogin = async () => {
    const data = {
      usuario: refUser.current.value,
      password: refPass.current.value,
    };
    setLoading(true)
		const resp = await enviarData(URL+"/auth", data)
    
		if(resp.status === 'ok')
		{
			props.isLogin(true);
      localStorage.setItem('jwt', resp.result.token)
      localStorage.setItem('username', refUser.current.value)
		}else{
      toast.error(resp.result.error_msg, { theme: "dark" });
      setLoading(false)
		}
  };

  return (
    <div className={`containerLogin ${loading ? "buttonPrc" : ""}`}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cntLogin">
        <div className="formLogin">
            <>
              <h1 style={{ color: "var(--main-color)" }}>Iniciar sesión</h1>
              <input
                onKeyUp={(e) => handleKeyPress(e)}
                placeholder="Usuario"
                style={{ border: "1px solid var(--main-color)" }}
                type="text"
                ref={refUser}
              />
              <input
                onKeyUp={(e) => handleKeyPress(e)}
                placeholder="Contraseña"
                style={{ border: "1px solid var(--main-color)" }}
                type={showPass ? "text" : "password"}
                ref={refPass}
              />
              <span className="showPass" onClick={() => setShowPass(!showPass)}>
                {showPass ? "Ocultar" : "Mostrar"} contraseña
              </span>
              <button onClick={handleLogin}>
                {loading ? <LoaderButton /> : "Ingresar"}
              </button>
            </>  
        </div>
        <div className="textLogin">
            <>
              <p>Bienvenido a CRM LTZ</p>
              <span>Ingresa para administrar tus productos</span> 
            </>
        </div>
      </div>
    </div>
  );
};

export default Login;
