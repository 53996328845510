import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AllRoutes = () => {
  
  return (
    <>
      <div className={`layout`}>
        
        <>
          <div className="layout__content">
            <div className="layout__content-main">
                <Suspense fallback={<h1>Cargando...</h1>}>
                  <Outlet />
                </Suspense>
            </div>
          </div>
        </>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default AllRoutes;
